<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <img src="../../assets/images/logo/logo-green.png" />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5" style="background-color:#f0f9f5 ;">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img fluid style="width: 70%;" :src="imgUrl" alt="Login V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="4" class="row auth-bg px-1 p-2 pt-3" style="border-left: solid 1px #182c25">
        <div class="col-md-12 pr-0 languages-mobile" align="end">
          <b-dropdown
            text="Dark"
            variant="outline-dark"
            id="dropdown-grouped"
            class="dropdown-language no-border-button"
            right
          >
            <template #button-content>
              <b-img
                :src="currentLocale.img"
                height="14px"
                width="22px"
                :alt="currentLocale.locale"
              />
              <span class="ml-50 text-body">{{ $t(currentLocale.name) }}</span>
            </template>
            <b-dropdown-item
              v-for="localeObj in locales"
              :key="localeObj.locale"
              @click="$i18n.locale = localeObj.locale"
            >
              <b-img
                :src="localeObj.img"
                height="14px"
                width="22px"
                :alt="localeObj.locale"
              />
              <span class="ml-50">{{ $t(localeObj.name) }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>

        <b-row v-if="chooseRole" class="col-12">
          <b-col cols="12" class="text-center m-0 p-0">
            <h3>{{$t('choose_your_role_in_platform')}}</h3>

            <b-row class="col-12">
              <b-col cols="6" class="text-center p-1 mt-0">
                <div style="border:2px solid #182c26;padding:50px 30px;cursor:pointer;" @click="registerAs('seller')">
                  <img src="seller.png" style="width:50px;" />
                  <br />
                </div>
                <br />
                <strong style="cursor:pointer;" @click="registerAs('seller')">{{ $t("seller") }}</strong>
              </b-col>
              <b-col cols="6" class="text-center p-1">
                <div style="border:2px solid #182c26;padding:50px 30px;cursor:pointer;" @click="registerAs('buyer')">
                  <img src="investor.png" style="width:50px;" />
                  <br />
                </div>
                <br />
                <strong style="cursor:pointer;text-transform: capitalize;" @click="registerAs('buyer')">{{ $t("investor") }}</strong>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-col v-else sm="12" md="12" lg="12" class="px-xl-2 mx-auto mt-2">
          <h4 class="row justify-content-between">
            <b-card-title
              title-tag="h2"
              class="font-weight-bold mb-2 col-md-12"
            >
              {{ $t("verify_your_account") }}
            </b-card-title>
          </h4>

          <b-card-text class="mb-2">
            {{
              $t("please_checkout_your_email_for_the_verification_pin_number")
            }}
          </b-card-text>

          <!-- form -->
          <validation-observer ref="verificationValidation">
            <b-form class="auth-login-form mt-2" @submit.prevent>
              <!-- email -->
              <b-form-group label="E-Mail" label-for="email">
                <validation-provider
                  #default="{ errors }"
                  name="email"
                  rules="required"
                >
                  <b-form-input
                    id="email"
                    v-model.trim="userEmail"
                    :state="errors.length > 0 ? false : null"
                    name="email"
                    readonly
                  />
                  <small class="text-danger">{{ $t(errors[0]) }}</small>
                </validation-provider>
              </b-form-group>
              <!-- pin -->
              <b-form-group label="PIN" label-for="pin-number">
                <validation-provider
                  #default="{ errors }"
                  name="PIN"
                  rules="required"
                >
                  <b-form-input
                    id="pin-number"
                    v-model.trim="pinNumber"
                    :state="errors.length > 0 ? false : null"
                    name="pin-number"
                    placeholder="1234"
                    :disabled="disableInputs"
                  />
                  <small class="text-danger">{{ $t(errors[0]) }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                @click="validationForm"
              >
                {{ $t("verify") }}
              </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2">
            <b-card-text role="button" @click="resend()">
              <span> {{ $t("resend_code") }}</span>
            </b-card-text>
          </b-card-text>
          <b-card-text class="text-center mt-2">
            <span> {{ $t("already_verified") }} </span>
            <b-link :to="{ name: 'login' }">
              <span>&nbsp; {{ $t("login_here") }} </span>
            </b-link>
          </b-card-text>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      pinNumber: "",
      disableInputs: false,
      userEmail: this.$store.state.auth.user.email,
      sideImg: require("@/assets/images/pages/login-v5.png"),
      // validation rulesimport store from '@/store/index'
      required,
      email,
      locales: [
        {
          locale: "en",
          img: "/images/flags/en.png",
          name: "english",
        },

        {
          locale: "de",
          img: "/images/flags/german.png",
          name: "german",
        },
      ],
      language: "",
      chooseRole: true,
    };
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/login-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
    currentLocale() {
      let obj = null;
      if (this.$i18n.locale) {
        if (this.$i18n.locale == "en") {
          obj = {
            locale: "en",
            img: "/images/flags/en.png",
            name: "english",
          };
        } else {
          obj = {
            locale: "de",
            img: "/images/flags/german.png",
            name: "german",
          };
        }
      } else {
        obj = {
          locale: "en",
          img: "/images/flags/en.png",
          name: "english",
        };
      }
      this.$store.commit("app/UPDATE_LANGUAGE", obj.locale);
      return obj;
    },
  },
  created(){
    if(this.$store.state.auth.user.role == null ){
      this.chooseRole = true;
    }else{
      this.chooseRole = false
    }
  },
  methods: {
    toggleLanguage(language) {
      this.$i18n.locale = language;
      this.language = language;
      this.$store.commit("app/UPDATE_LANGUAGE", language);
    },
    registerAs(type) {
  const token = this.$store.state.auth.user.token;

  this.$http
    .post("/setRole", 
      { role: type },  // Request body
      { headers: { Authorization: `Bearer ${token}` } }  // Headers
    )
    .then((res) => {
      if (res) {
        this.chooseRole = false;
      }
    })
    .catch(() => {
      // Handle error
    });
},

    
    validationForm() {
      this.$refs.verificationValidation.validate().then((success) => {
        if (success) {
          this.disableInputs = true;
          this.$http
            .post("/verify", {
              email: this.$store.state.auth.user.email,
              code: this.pinNumber,
              language: this.currentLocale.locale,
            })
            .then((res) => {
              if (res.data.value.status === 1) {
                const user = res.data.value;
                this.$store.commit("auth/SET_AUTHENTICATED", true);
                this.$store.commit("auth/SET_USER", user);
                this.$store.commit("auth/UPDATE_TOKEN", user.token);
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: this.$t("Mail_verified_successfully"),
                    icon: "SuccessIcon",
                    variant: "success",
                  },
                });
                window.location.href = "/";
              } else {
                this.disableInputs = false;
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: this.$t("E-Mail_failed_verification!"),
                    icon: "ErrorIcon",
                    variant: "error",
                  },
                });
              }
            })
            .catch(() => {
              this.disableInputs = false;
              this.$store.commit("auth/SET_USER", []);
              this.$store.commit("auth/SET_AUTHENTICATED", false);
              this.$store.commit("auth/UPDATE_TOKEN", null);
            });
        }
      });
    },
    resend() {
      this.$http
        .post("/resend", {
          email: this.$store.state.auth.user.email,
        })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("E-Mail_send_successfully"),
              icon: "SuccessIcon",
              variant: "success",
            },
          });
        })
        .catch(() => {
          this.$store.commit("auth/SET_USER", []);
          this.$store.commit("auth/SET_AUTHENTICATED", false);
          this.$store.commit("auth/UPDATE_TOKEN", null);
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
